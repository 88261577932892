import { useEffect, useState } from 'react'

import { navigate, useLocation } from '@redwoodjs/router'

export const usePage = (): [number, (newPage: number) => void] => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const pageFromUrl = parseInt(queryParams.get('page'), 10)

  const [page, setPageState] = useState(isNaN(pageFromUrl) ? 1 : pageFromUrl)

  const setPage = (newPage: number) => {
    if (page !== newPage) {
      queryParams.set('page', newPage.toString())
      setPageState(newPage)
      navigate(`?${queryParams.toString()}`)
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    const pageFromUrl = parseInt(queryParams.get('page'), 10)
    setPageState(isNaN(pageFromUrl) ? 1 : pageFromUrl)
  }, [search])

  return [page, setPage]
}
